import React from 'react';
import * as ReactGA from 'react-ga';
import Layout from '../components/Shared/Layout';
import ProjectDeliveryProcess from '../components/project_delivery';
import '../assets/css/process.css';

const $ = typeof window !== `undefined` ? require('jquery') : null;

class PDPPage extends React.Component {
  /* constructor(props) {
      super(props);
  } */
  componentDidMount() {
    ReactGA.set({
      title: 'ProjectDeliveryProcess.Page',
    });
    ReactGA.pageview(this.props.location.pathname);

    $(document).ready(() => {
      let classActive;
      $('#get-in-touch').addClass('get-in-touch');
      $('#get-in-touch').parent().addClass('get-in-touch-item');
      $('#get-in-touch').parent().addClass('list-item');

      $('.content').on('click', function (e) {
        classActive = $(this).attr('id');
        const processStep = ['get-in-touch', 'call', 'discovery', 'ballpark', 'contract'];
        processStep.forEach(Id => {
          $(`#${Id}`).removeClass(Id);
          $(`#${Id}`).parent().removeClass(`${Id}-item`);
          $(`#${Id}`).parent().removeClass('list-item');
        });
        $(this).addClass(classActive);
        $(this).parent().addClass(`${classActive}-item`);
        $(this).parent().addClass('list-item');
      });

      $('.readmore').click(function (e) {
        e.preventDefault();
        const btn = $(this).attr('id');
        if ($(`#${btn}-section`).css('display') === 'none') {
          $(`#${btn}-section`).fadeIn();
          $(`#${btn}`).children('u').text('View Less');
          $(`#${btn}`).addClass('latoBold');
          $(`#${btn}`).css({
            'text-decoration': 'underline',
            color: '#0EC573',
          });
        } else {
          $(`#${btn}-section`).fadeOut();
          $(`#${btn}`).children('u').text('Read More');
          $(`#${btn}`).addClass('latoBold');
          $(`#${btn}`).css({
            'text-decoration': 'underline',
            color: '#0EC573',
          });
        }
      });
    });
  }

  render() {
    return (
      <Layout>
        <ProjectDeliveryProcess />
      </Layout>
    );
  }
}

export default PDPPage;
