import React from 'react';
// import { Link } from "gatsby";
// import 'mdbreact/dist/css/mdb.css'
// import { MDBAnimation } from "mdbreact";
import {Helmet} from 'react-helmet';

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Tintash</title>
    </Helmet>
    <div>
      <section className="banner">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <h1 className="latoBlack text-left text-white heading">
                Pre Kick-off Delivery Process
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div id="timeline" className="timeline">
          <div className="contain contain-get-in-touch left">
            <div id="get-in-touch" className="content p-2">
              <div className="d-flex d-row align-items-center">
                <div className="d-flex d-row align-items-center" style={{flex: 4}}>
                  <img
                    src={require('../assets/images/services/process/get-in-touch.png').default}
                    alt="img"
                  />
                  <h2 className="latoBlack heading pl-2 mb-0">Get in Touch</h2>
                </div>
              </div>
              <p className="description">
                Our journey starts with you reaching out to us through our{' '}
                <a href="/contact">
                  <u>Contact Us</u>
                </a>{' '}
                form.
              </p>
              <p className="description">
                Once we receive an inquiry from you, someone from our client success team will reach
                out, with the objective of setting up an introductory call.
              </p>
            </div>
          </div>
          <div className="contain contain-call right">
            <div id="call" className="content">
              <div className="d-flex d-row align-items-center pt-1">
                <img
                  src={require('../assets/images/services/process/introductory-call.png').default}
                  alt="img"
                />
                <h2 className="latoBlack heading pl-2 mb-0">Introductory Call</h2>
              </div>
              <p className="description">
                The intro call, taken by either a Key Account Manager or a Technical Project Lead
                together with a Proposal Manager. Usually, an NDA is signed before the intro call so
                that it’s easy to have an open discussion
              </p>
              <div>
                <p className="description">
                  The next step beyond the intro call is to receive a set of requirements from you
                  so we can proceed to provide you a ballpark, so you can have a better sense of
                  budgeting if you choose Tintash.
                </p>
              </div>
            </div>
          </div>
          <div className="contain contain-discovery left">
            <div id="discovery" className="content">
              <div className="d-flex d-row align-items-center pt-1">
                <img
                  src={require('../assets/images/services/process/discovery.png').default}
                  alt="img"
                />
                <h2 className="latoBlack heading pl-2 mb-0">Discovery</h2>
              </div>
              <p className="description">
                The discovery stage starts with you sharing your requirements.
              </p>
              <p className="description">
                These requirements can be in the form of: <br />
              </p>
              <p className="description pt-0">
                A) A very high level write up, with a description of the features you’d like
                developed
              </p>
              <div id="view-all-call-section" style={{display: 'none'}}>
                <p className="description pt-0">
                  B) A feature breakdown with a high-level description of use cases and references
                </p>
                <p className="description  pt-1">
                  C) A feature breakdown with detailed use cases, with references, but no wireframes
                </p>
                <p className="description  pt-1">
                  D) A feature breakdown with detailed uses cases, references, and wireframes
                </p>
                <p className="description">
                  This is where someone from the technical team will usually get involved internally
                  at Tintash and will go through the set of requirements and come up with questions
                  from our side.
                </p>
                <p className="description">
                  As a next step, we get on a call with you to go through our questions to get a
                  better sense of your requirements.Once we have this in place, we then proceed
                  forward to the estimates stage.
                </p>
              </div>
              {/* Read More */}
              <div className="text-left mt-2">
                <a href="#" id="view-all-call" name="btn_viewall_logo" className="readmore">
                  <u style={{color: '#0EC573', textDecoration: 'none'}} className="latoBold">
                    Read More
                  </u>
                </a>
              </div>
              {/* Read More */}
            </div>
          </div>
          <div className="contain contain-ballpark right">
            <div id="ballpark" className="content">
              <div className="d-flex d-row align-items-center pt-1">
                <img
                  src={require('../assets/images/services/process/ballpark.png').default}
                  alt="img"
                />
                <h2 className="latoBlack heading pl-2 mb-0">Ballpark</h2>
              </div>
              <p className="description">
                In the case of options A, B, and C mentioned above we can provide a high-level
                ballpark.
              </p>
              <p className="description">
                In the case of D, we can provide a detailed breakdown depending on the availability
                of Technical Experts or Technical Project Leads at our end.
              </p>
              <div id="view-all-ballpark-section" style={{display: 'none'}}>
                <p className="description">
                  It’s very likely that after going over the high-level ballpark or breakdown on a
                  call, you’d like to internally review it a bit further. Usually, our clients take
                  a few days to discuss these ballparks and breakdowns internally and then like to
                  get on a follow-up call to address any further questions they might have.
                </p>
              </div>
              {/* Read More */}
              <div className="text-left mt-2">
                <a href="#" id="view-all-ballpark" name="btn_viewall_logo" className="readmore">
                  <u style={{color: '#0EC573', textDecoration: 'none'}} className="latoBold">
                    Read More
                  </u>
                </a>
              </div>
              {/* Read More */}
            </div>
          </div>
          <div className="contain contain-contract left">
            <div id="contract" className="content">
              <div className="d-flex d-row align-items-center pt-1">
                <img
                  src={require('../assets/images/services/process/contract-logistics.png').default}
                  alt="img"
                />
                <h2 className="latoBlack heading pl-2 mb-0">Contract &amp; Logistics</h2>
              </div>
              <p className="description">
                Once internal dissertations are done, our clients decide whether they’d like to take
                things to the contract stage with us, based on what they’ve seen through the
                different stages of engagement with us.
              </p>
              <p className="description">
                If you decide to move forward with us, then the next stage would be the contracting
                stage where we’ll finalize the terms and logistics of the agreement.
              </p>
              <p className="description">
                After the agreement is finalised and everything looks good, the relevant Project
                Lead is introduced who then takes the project forward beyond the Kickoff stage.
              </p>
            </div>
          </div>
          <div className="vertical-timeline" />
        </div>
      </div>
    </div>
  </>
);
